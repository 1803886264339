body {
	background-color: #243673;
	color: white;
}

.hr-gradient {
	display: block;
	height: 1px;
	width: 100%;
	border: 0;
	background: white;
	margin: 20px 0;
}

.login-button {
	background-color: #1E2B4B;
	font-size: 20px;
	font-weight: 500;
	padding: 0.6em 2em;
	line-height: 1.7em !important;
	cursor: pointer;
	color: white;
	background-position: 50%;
	background-repeat: no-repeat;
	border: none;
	border-radius: 3px;
	-webkit-transition-duration: 0.2s;
	transition-duration: 0.2s;
	-webkit-transition-property: all !important;
	transition-property: all !important;
}

.action-button {
	background-color: #1E2B4B;
	font-size: 12px;
	width: auto;
	font-weight: 500;
	padding: 0.3em 1em;
	line-height: 1.7em !important;
	cursor: pointer;
	color: white;
	background-position: 50%;
	background-repeat: no-repeat;
	border: none;
	border-radius: 3px;
	-webkit-transition-duration: 0.2s;
	transition-duration: 0.2s;
	-webkit-transition-property: all !important;
	transition-property: all !important;
}

.logout {
	background: red;
}

.flex-container {
	display: flex;
	flex-direction: row;
	/* Change to column if you want the buttons stacked */
	justify-content: space-around;
	/* This will evenly space the buttons. You can change it as needed. */
	align-items: center;
	/* This ensures the buttons are aligned in the center vertically. */
	flex-wrap: wrap;
	/* This allows items to wrap onto the next line if they don't fit. */
}

.flex-container>div {
	flex-basis: calc(33.3% - 20px);
	/* Adjust this to change the button width. The 20px accounts for margins. */
	margin: 10px;
	/* This adds space between the buttons. */
	text-align: center;
}

.action-button {
	width: 100%;
	/* This makes each button take up the full width of its container div. */
	padding: 10px 20px;
	/* Adjust padding to control the button size. */
	/* Add other button styles (e.g., font size, border, etc.) as needed */
}

.footer-content {
	margin-top: 10px;
	width: 100%;
	display: block;
	text-align: center;
}

.container {
	width: 60%;
	margin: auto;
	padding: 0 2rem;
}

.main {
	min-height: 100vh;
	padding: 4rem 0;
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.title {
	line-height: 1.15;
	font-size: 3rem;
	text-align: center;
	margin: 50px;
}

.title a {
	color: #0070f3;
	text-decoration: none;
}

.grid {
	display: flex;
	align-items: center;
	flex-direction: column;
}

.card {
	margin: 0.5rem;
	padding: 0.7rem;
	text-align: center;
	color: #0070f3;
	background-color: #fafafa;
	text-decoration: none;
	border: 1px solid #0070f3;
	border-radius: 10px;
	transition: color 0.15s ease, border-color 0.15s ease;
	width: 100%;
}

.card:hover,
.card:focus,
.card:active {
	cursor: pointer;
	background-color: #f1f1f1;
}

.footer {
	display: flex;
	flex-direction: column;
	padding: 2rem 0;
	justify-content: center;
	align-items: stretch;
	margin-top: 10rem;
}

.footer a {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-grow: 1;
}

.logo {
	height: 100px;
	margin-left: 0.5rem;
}

@media (max-width: 1200px) {
	.container {
		width: 100%;
	}
}

.flex-container {
	display: flex;
	flex-flow: row wrap;
}

.flex-container>div {
	width: 100px;
	margin: 10px;
	text-align: center;
	line-height: 75px;
	font-size: 30px;
}

#console {
	width: 100%;
	height: 100%;
	overflow: auto;
	word-wrap: break-word;
	font-size: 16px;
	font-family: monospace;
}